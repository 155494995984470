import React from 'react';
import { Link } from 'react-router-dom';
import { 
  CircuitBoard, 
  Cpu, 
  Wrench,
  Phone, 
  Mail, 
  MapPin, 
  Facebook, 
  Linkedin, 
  Youtube,
  Clock,
  Flag
} from 'lucide-react';
import { FooterLinks } from './FooterLinks';

export function Footer() {
  return (
    <footer className="relative bg-gray-900 pt-16 pb-8 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold text-white mb-4">KGS Otomasyon</h3>
            <p className="text-gray-400 mb-4">
              Endüstriyel otomasyon çözümleri ve PLC programlama hizmetleri.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-blue-500">
                <Facebook className="w-5 h-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-blue-500">
                <Linkedin className="w-5 h-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-blue-500">
                <Youtube className="w-5 h-5" />
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Hizmetlerimiz</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/faliyetlerimiz" className="text-gray-400 hover:text-blue-500">
                  PLC Programlama
                </Link>
              </li>
              <li>
                <Link to="/uygulamalar" className="text-gray-400 hover:text-blue-500">
                  Endüstriyel Otomasyon
                </Link>
              </li>
              <li>
                <Link to="/tamir-bakim" className="text-gray-400 hover:text-blue-500">
                  Bakım Hizmetleri
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-white mb-4">İletişim</h3>
            <ul className="space-y-2">
              <li className="flex items-center text-gray-400">
                <Phone className="w-5 h-5 mr-2 text-blue-400" />
                0850 302 97 57
              </li>
              <li className="flex items-center text-gray-400">
                <Mail className="w-5 h-5 mr-2 text-blue-400" />
                info@kgs.com.tr
              </li>
              <li className="flex items-start text-gray-400">
                <MapPin className="w-5 h-5 mr-2 text-blue-400 mt-1" />
                <span>
                  Sanayi Mahallesi Kozalı Sokak Çarşı Yapı AVM No:10 B2 Blok İzmit/Kocaeli
                </span>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Çalışma Saatleri</h3>
            <ul className="space-y-2">
              <li className="flex items-center text-gray-400">
                <Clock className="w-5 h-5 mr-2 text-blue-400" />
                Pazartesi - Cuma: 09:00 - 18:00
              </li>
              <li className="text-gray-400 ml-7">
                Cumartesi: 09:00 - 13:00
              </li>
              <li className="text-gray-400 ml-7">
                Pazar: Kapalı
              </li>
            </ul>
          </div>
        </div>

        <FooterLinks />
      </div>

      {/* SEO Optimization - Hidden Text */}
      <div className="sr-only">
        <h2>KGS Otomasyon - Kocaeli Endüstriyel Otomasyon Çözümleri</h2>
        <p>
          Kocaeli'nin lider endüstriyel otomasyon firması. PLC programlama, SCADA sistemleri,
          endüstriyel bakım ve Siemens çözüm ortağı. İzmit, Gebze, Darıca, Çayırova, Dilovası
          bölgelerinde hizmet vermekteyiz.
        </p>
        <p>
          Endüstriyel otomasyon, PLC programlama, SCADA sistemleri, HMI panel tamiri,
          endüstriyel PC tamiri, Siemens servis, otomasyon projeleri, endüstriyel bakım
        </p>
      </div>
    </footer>
  );
}