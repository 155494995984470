import React from 'react';

export function ElectricEffect() {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {/* Horizontal Electric Lines */}
      <div className="absolute top-1/2 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-400/50 to-transparent animate-pulse" />
      <div className="absolute top-1/2 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-400/30 to-transparent animate-pulse delay-75" />
      
      {/* Vertical Electric Lines */}
      <div className="absolute left-1/2 top-0 h-full w-px bg-gradient-to-b from-transparent via-blue-400/50 to-transparent animate-pulse" />
      <div className="absolute left-1/2 top-0 h-full w-px bg-gradient-to-b from-transparent via-blue-400/30 to-transparent animate-pulse delay-75" />
      
      {/* Corner Electric Sparks */}
      {[...Array(4)].map((_, i) => (
        <div
          key={i}
          className="absolute w-2 h-2 bg-blue-400 rounded-full animate-ping"
          style={{
            top: i < 2 ? '0' : 'auto',
            bottom: i >= 2 ? '0' : 'auto',
            left: i % 2 === 0 ? '0' : 'auto',
            right: i % 2 === 1 ? '0' : 'auto',
            animationDelay: `${i * 0.2}s`,
          }}
        />
      ))}
      
      {/* Electric Arcs */}
      {[...Array(2)].map((_, i) => (
        <div
          key={i}
          className="absolute inset-0"
          style={{ transform: `rotate(${i * 90}deg)` }}
        >
          <div className="absolute top-0 left-1/2 w-px h-full bg-gradient-to-b from-transparent via-blue-400/20 to-transparent animate-pulse" style={{ animationDelay: `${i * 0.3}s` }} />
        </div>
      ))}
      
      {/* Pulsing Glow */}
      <div className="absolute inset-0 bg-blue-400/5 rounded-lg animate-pulse" />
    </div>
  );
}