import { z } from 'zod';

export const languageSchema = z.enum(['tr', 'en']);
export type Language = z.infer<typeof languageSchema>;

export const translations = {
  tr: {
    common: {
      menu: {
        about: 'Hakkımızda',
        activities: 'Faliyetlerimiz',
        applications: 'Uygulamalar',
        maintenance: 'Tamir & Bakım',
        references: 'Referanslar',
        blog: 'Blog',
        contact: 'İletişim'
      },
      footer: {
        rights: 'Tüm hakları saklıdır.',
        privacy: 'Gizlilik Politikası',
        terms: 'Kullanım Koşulları'
      },
      contact: {
        title: 'İletişim',
        name: 'Ad Soyad',
        email: 'E-posta',
        message: 'Mesaj',
        send: 'Gönder',
        sending: 'Gönderiliyor...'
      },
      pages: {
        about: {
          title: 'Hakkımızda',
          description: 'KGS Otomasyon Medikal San. Tic. Ltd. Şti.'
        },
        applications: {
          title: 'Uygulamalar',
          description: 'Endüstriyel Otomasyon Çözümleri'
        },
        maintenance: {
          title: 'Tamir & Bakım',
          description: 'Profesyonel Servis Hizmetleri'
        },
        references: {
          title: 'Referanslar',
          description: 'Müşterilerimiz ve Projelerimiz'
        }
      }
    }
  },
  en: {
    common: {
      menu: {
        about: 'About Us',
        activities: 'Activities',
        applications: 'Applications',
        maintenance: 'Maintenance',
        references: 'References',
        blog: 'Blog',
        contact: 'Contact'
      },
      footer: {
        rights: 'All rights reserved.',
        privacy: 'Privacy Policy',
        terms: 'Terms of Service'
      },
      contact: {
        title: 'Contact',
        name: 'Full Name',
        email: 'Email',
        message: 'Message',
        send: 'Send',
        sending: 'Sending...'
      },
      pages: {
        about: {
          title: 'About Us',
          description: 'KGS Automation Medical Inc.'
        },
        applications: {
          title: 'Applications',
          description: 'Industrial Automation Solutions'
        },
        maintenance: {
          title: 'Maintenance',
          description: 'Professional Service Solutions'
        },
        references: {
          title: 'References',
          description: 'Our Clients and Projects'
        }
      }
    }
  }
} as const;