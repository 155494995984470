import React from 'react';

export function DataFlow() {
  return (
    <div className="absolute inset-0 pointer-events-none">
      <div className="absolute w-full h-full">
        {[...Array(8)].map((_, i) => (
          <div
            key={i}
            className="absolute h-2 w-2 rounded-full bg-blue-400/40 animate-data-flow"
            style={{
              top: `${Math.random() * 100}%`,
              left: '-10px',
              animationDelay: `${i * 1.5}s`
            }}
          />
        ))}
      </div>
    </div>
  );
}