import React from 'react';
import { Phone, Mail, MapPin } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import axios from 'axios';

const contactSchema = z.object({
  name: z.string().min(2, 'İsim en az 2 karakter olmalıdır'),
  email: z.string().email('Geçerli bir email adresi giriniz'),
  message: z.string().min(10, 'Mesaj en az 10 karakter olmalıdır')
});

type ContactForm = z.infer<typeof contactSchema>;

export function ContactSection() {
  const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<ContactForm>({
    resolver: zodResolver(contactSchema)
  });

  const onSubmit = async (data: ContactForm) => {
    try {
      const response = await axios.post('/api/contact.php', data);
      if (response.data.success) {
        alert('Mesajınız başarıyla gönderildi');
        reset();
      }
    } catch (error) {
      alert('Mesaj gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
    }
  };

  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-white text-center mb-12">İletişim</h2>
        
        <div className="grid md:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div className="bg-white/5 backdrop-blur-sm p-6 rounded-xl">
              <h3 className="text-xl font-semibold text-white mb-4">İletişim Bilgileri</h3>
              
              <div className="space-y-4">
                <div className="flex items-start">
                  <MapPin className="w-5 h-5 text-blue-400 mt-1 mr-3" />
                  <p className="text-gray-300">
                    Sanayi Mahallesi Kozalı Sokak Çarşı Yapı AVM No:10 B2 Blok İzmit/Kocaeli
                  </p>
                </div>

                <div className="flex items-center">
                  <Phone className="w-5 h-5 text-blue-400 mr-3" />
                  <p className="text-gray-300">0850 302 97 57</p>
                </div>

                <div className="flex items-center">
                  <Mail className="w-5 h-5 text-blue-400 mr-3" />
                  <p className="text-gray-300">info@kgs.com.tr</p>
                </div>
              </div>
            </div>

            <div className="bg-white/5 backdrop-blur-sm p-6 rounded-xl">
              <h3 className="text-xl font-semibold text-white mb-4">Banka Bilgileri</h3>
              <div className="space-y-2 text-gray-300">
                <p>Finansbank İzmit Sanayi Şubesi</p>
                <p>TL Hesabı: 43601474</p>
                <p>IBAN: TR62 0011 1000 0000 0043 6014 74</p>
                <p className="mt-4">EURO Hesabı: 43601664</p>
                <p>IBAN: TR73 0011 1000 0000 0043 6016 64</p>
              </div>
            </div>
          </div>

          <div className="bg-white/5 backdrop-blur-sm p-6 rounded-xl">
            <h3 className="text-xl font-semibold text-white mb-4">İletişim Formu</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2">Ad Soyad</label>
                <input 
                  {...register('name')}
                  className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
                />
                {errors.name && (
                  <p className="text-red-400 text-sm mt-1">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-300 mb-2">E-posta</label>
                <input 
                  {...register('email')}
                  className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
                />
                {errors.email && (
                  <p className="text-red-400 text-sm mt-1">{errors.email.message}</p>
                )}
              </div>

              <div>
                <label className="block text-gray-300 mb-2">Mesaj</label>
                <textarea 
                  {...register('message')}
                  rows={4}
                  className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
                ></textarea>
                {errors.message && (
                  <p className="text-red-400 text-sm mt-1">{errors.message.message}</p>
                )}
              </div>

              <button 
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Gönderiliyor...' : 'Gönder'}
              </button>
            </form>
          </div>
        </div>

        <div className="mt-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.9554830856247!2d29.91047231570937!3d40.76520197932784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb5babc37b9e6d%3A0x9e13b89b89f1b1a1!2sKGS%20Otomasyon!5e0!3m2!1str!2str!4v1647887234567!5m2!1str!2str"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            className="rounded-xl"
          ></iframe>
        </div>
      </div>
    </div>
  );
}