import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Menu, 
  Phone, 
  Mail,
  Factory,
  CircuitBoard,
  Cpu,
  Wrench,
  BookMarked,
  MessageSquare,
  FileText
} from 'lucide-react';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const location = useLocation();

  const menuItems = [
    { path: '/hakkimizda', label: 'Hakkımızda', icon: Factory },
    { path: '/faliyetlerimiz', label: 'Faliyetlerimiz', icon: Cpu },
    { path: '/uygulamalar', label: 'Uygulamalar', icon: CircuitBoard },
    { path: '/tamir-bakim', label: 'Tamir & Bakım', icon: Wrench },
    { path: '/referanslar', label: 'Referanslar', icon: BookMarked },
    { path: '/blog', label: 'Blog', icon: FileText },
    { path: '/iletisim', label: 'İletişim', icon: MessageSquare },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className="fixed w-full z-50">
      {/* Üst Bilgi Çubuğu */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white py-2">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center text-sm">
            <div className="flex items-center space-x-6">
              <a href="tel:+908503029757" className="flex items-center hover:text-blue-100">
                <Phone className="w-4 h-4 mr-2" />
                <span>0850 302 97 57</span>
              </a>
              <a href="mailto:info@kgs.com.tr" className="flex items-center hover:text-blue-100">
                <Mail className="w-4 h-4 mr-2" />
                <span>info@kgs.com.tr</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Ana Menü */}
      <div className="bg-white/95 backdrop-blur-sm shadow-lg">
        <div className="container mx-auto px-4">
          <nav className="flex items-center justify-between h-20">
            <Link to="/" className="flex items-center">
              <img 
                src="https://kgsotomasyon.com/wp-content/uploads/2013/04/KGS_Otomasyon_logo_k.png" 
                alt="KGS Otomasyon Logo" 
                className="h-12"
              />
            </Link>

            {/* Masaüstü Menü */}
            <div className="hidden md:flex items-center space-x-6">
              {menuItems.map((item) => {
                const Icon = item.icon;
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`flex items-center text-gray-700 hover:text-blue-600 transition-colors ${
                      isActive(item.path) ? 'text-blue-600' : ''
                    }`}
                  >
                    <Icon className="w-4 h-4 mr-2" />
                    <span>{item.label}</span>
                  </Link>
                );
              })}
            </div>

            {/* Mobil Menü Butonu */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 rounded-lg hover:bg-gray-100"
              aria-label="Toggle menu"
            >
              <Menu className="w-6 h-6 text-gray-700" />
            </button>
          </nav>
        </div>

        {/* Mobil Menü */}
        {isMenuOpen && (
          <div className="md:hidden border-t border-gray-100">
            <div className="container mx-auto px-4 py-4">
              {menuItems.map((item) => {
                const Icon = item.icon;
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`flex items-center text-gray-700 hover:text-blue-600 py-2 ${
                      isActive(item.path) ? 'text-blue-600' : ''
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Icon className="w-4 h-4 mr-2" />
                    <span>{item.label}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </header>
  );
}