import React from 'react';

export function PlcRack() {
  return (
    <div className="absolute left-10 bottom-20 w-48 h-32 opacity-30">
      <svg viewBox="0 0 120 80" className="w-full h-full">
        <rect x="10" y="10" width="100" height="60" className="fill-gray-800 stroke-blue-400" strokeWidth="2" />
        {[...Array(4)].map((_, i) => (
          <g key={i} className="animate-module-pulse" style={{ animationDelay: `${i * 0.5}s` }}>
            <rect
              x={20 + (i * 25)}
              y="20"
              width="20"
              height="40"
              className="fill-gray-700 stroke-blue-400"
              strokeWidth="1"
            />
            <circle
              cx={30 + (i * 25)}
              cy="30"
              r="2"
              className="fill-green-400 animate-blink"
              style={{ animationDelay: `${i * 0.3}s` }}
            />
          </g>
        ))}
      </svg>
    </div>
  );
}