import React from 'react';
import { ArrowRight } from 'lucide-react';
import { RobotArm } from './animations/RobotArm';
import { CodeBlock } from './animations/CodeBlock';
import { Circuit } from './animations/Circuit';
import { DataFlow } from './animations/DataFlow';
import { PlcRack } from './animations/PlcRack';
import { BinaryCode } from './animations/BinaryCode';

export function Hero() {
  return (
    <div className="relative h-[80vh] bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px]"></div>
        <Circuit />
        <RobotArm />
        <CodeBlock />
        <DataFlow />
        <PlcRack />
        <BinaryCode />
      </div>
      
      <div className="relative container mx-auto px-4 h-full flex items-center">
        <div className="max-w-3xl mx-auto space-y-6 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white animate-fade-in">
            Kocaeli Otomasyon Çözümleri
          </h1>
          <p className="text-lg text-gray-300 animate-fade-in-delay">
            Weidmuller, SICK ve MURR ürünleriyle endüstriyel otomasyon ve PLC programlama çözümleri sunuyoruz.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <a href="/iletisim" className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-all transform hover:scale-105 inline-flex items-center">
              Bizimle İletişime Geçin
              <ArrowRight className="ml-2 w-4 h-4" />
            </a>
            <a href="/uygulamalar" className="bg-white/10 text-white px-6 py-2 rounded-full hover:bg-white/20 transition-all transform hover:scale-105">
              Çözümlerimizi Keşfedin
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}