import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { ApplicationsGrid } from '../components/applications/ApplicationsGrid';
import { ApplicationsHero } from '../components/applications/ApplicationsHero';

export function ApplicationsPage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Endüstriyel Otomasyon Uygulamaları | KGS Otomasyon</title>
        <meta name="description" content="LED panel, çözücü otomasyonu, sarıcı otomasyonu, tank SCADA sistemleri ve daha fazlası. Endüstriyel tesisler için özel otomasyon çözümleri sunuyoruz." />
        <meta name="keywords" content="endüstriyel otomasyon, PLC programlama, SCADA sistemleri, LED panel, tank otomasyonu, sarıcı otomasyonu, çözücü otomasyonu, Kocaeli otomasyon" />
        <link rel="canonical" href="https://kgsotomasyon.com/uygulamalar" />
        <meta property="og:title" content="Endüstriyel Otomasyon Uygulamaları | KGS Otomasyon" />
        <meta property="og:description" content="LED panel, çözücü otomasyonu, sarıcı otomasyonu, tank SCADA sistemleri ve daha fazlası. Endüstriyel tesisler için özel otomasyon çözümleri sunuyoruz." />
        <meta property="og:url" content="https://kgsotomasyon.com/uygulamalar" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <Header />
      <main>
        <ApplicationsHero />
        <ApplicationsGrid />
      </main>
      <Footer />
    </div>
  );
}