import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { IndustrialPCRepair } from '../components/maintenance/IndustrialPCRepair';
import { OperatorPanelRepair } from '../components/maintenance/OperatorPanelRepair';
import { Phone, Mail } from 'lucide-react';

export function MaintenancePage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Tamir & Bakım Hizmetleri | KGS Otomasyon</title>
        <meta name="description" content="Endüstriyel PC ve operatör panelleri için profesyonel tamir ve bakım hizmetleri. Siemens, ESA, Artech, Advantech, B&R ve diğer markalar için servis." />
        <meta name="keywords" content="endüstriyel PC tamiri, operatör panel tamiri, Siemens panel tamiri, PLC tamiri, Kocaeli otomasyon servisi" />
        <link rel="canonical" href="https://kgsotomasyon.com/tamir-bakim" />
      </Helmet>
      
      <Header />
      
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h1 className="text-4xl font-bold text-white mb-4">Tamir & Bakım Hizmetleri</h1>
            <p className="text-gray-300">
              Endüstriyel PC ve operatör panelleriniz için profesyonel tamir ve bakım hizmetleri sunuyoruz.
            </p>
          </div>

          <div className="space-y-12">
            <IndustrialPCRepair />
            <OperatorPanelRepair />
          </div>

          <div className="mt-12 bg-white/5 backdrop-blur-sm rounded-lg p-8">
            <h2 className="text-2xl font-bold text-white mb-6 text-center">İletişim Bilgileri</h2>
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-12">
              <a 
                href="tel:+908503029757"
                className="flex items-center text-gray-300 hover:text-blue-400 transition-colors"
              >
                <Phone className="w-5 h-5 mr-2" />
                0850 302 97 57
              </a>
              <a 
                href="mailto:otomasyon@kgs.com.tr"
                className="flex items-center text-gray-300 hover:text-blue-400 transition-colors"
              >
                <Mail className="w-5 h-5 mr-2" />
                otomasyon@kgs.com.tr
              </a>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}