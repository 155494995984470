import React from 'react';
import { Monitor, HardDrive, Fan, Cpu, Power, Thermometer } from 'lucide-react';

const services = [
  {
    icon: Monitor,
    title: "Ekran Değişimi ve Onarımı",
    description: "Dokunmatik ekran ve LCD ekranların değişimi, kalibrasyon ayarları ve onarımı"
  },
  {
    icon: HardDrive,
    title: "Depolama Birimi Servisi",
    description: "HDD, SSD, CF card değişimleri, veri kurtarma ve yedekleme işlemleri"
  },
  {
    icon: Power,
    title: "Güç Ünitesi Servisi",
    description: "Power supply ve güç kaynaklarının bakım ve onarımı"
  },
  {
    icon: Cpu,
    title: "Anakart Onarımı",
    description: "Anakart arızalarının tespiti ve onarımı"
  },
  {
    icon: Fan,
    title: "Soğutma Sistemi",
    description: "Fan değişimi ve bakımı, soğutma sistemi optimizasyonu"
  },
  {
    icon: Thermometer,
    title: "Isı Yönetimi",
    description: "Aşırı ısınma sorunlarının giderilmesi ve önleyici bakım"
  }
];

export function IndustrialPCRepair() {
  return (
    <div className="space-y-8">
      <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
        <h2 className="text-2xl font-bold text-white mb-4">Endüstriyel PC Tamiri</h2>
        <p className="text-gray-300 mb-6">
          Endüstriyel PCler, endüstriyel alanlar için özel olarak üretilmiş bilgisayarlardır. 
          KGS Otomasyon olarak Siemens, ESA, Artech, Advantech, B&R gibi markaların endüstriyel 
          PClerinin tamirini ve bakımını yapmaktayız.
        </p>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => {
            const Icon = service.icon;
            return (
              <div 
                key={index}
                className="bg-white/5 rounded-lg p-6 hover:bg-white/10 transition-all duration-300"
              >
                <Icon className="w-8 h-8 text-blue-400 mb-4" />
                <h3 className="text-lg font-semibold text-white mb-2">{service.title}</h3>
                <p className="text-gray-400 text-sm">{service.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}