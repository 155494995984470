import axios from 'axios';
import { Post } from '../types';

const api = axios.create({
  baseURL: import.meta.env.PROD ? 'https://kgsotomasyon.com/api' : '/api'
});

export const postsApi = {
  async getAll(): Promise<Post[]> {
    try {
      const response = await api.get<Post[]>('/posts');
      return response.data;
    } catch (error) {
      console.error('Blog posts yüklenirken hata:', error);
      return [];
    }
  },

  async getLatest(): Promise<Post[]> {
    try {
      const response = await api.get<Post[]>('/posts?limit=5');
      return response.data;
    } catch (error) {
      console.error('Son blog yazıları yüklenirken hata:', error);
      return [];
    }
  },

  async getById(id: number): Promise<Post | null> {
    try {
      const response = await api.get<Post>(`/posts/${id}`);
      return response.data;
    } catch (error) {
      console.error('Blog post detayı yüklenirken hata:', error);
      return null;
    }
  }
};