import React, { useState, useEffect } from 'react';

interface CodeWindowProps {
  code: string;
}

export function CodeWindow({ code }: CodeWindowProps) {
  const [displayedCode, setDisplayedCode] = useState('');
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= code.length) {
        setDisplayedCode(code.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    const cursorInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);

    return () => {
      clearInterval(interval);
      clearInterval(cursorInterval);
    };
  }, [code]);

  return (
    <div className="bg-gray-900/90 rounded-lg overflow-hidden backdrop-blur-sm border border-blue-500/20">
      {/* Terminal Header */}
      <div className="bg-gray-800 px-4 py-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="w-3 h-3 rounded-full bg-red-500/80 animate-pulse" />
          <div className="w-3 h-3 rounded-full bg-yellow-500/80 animate-pulse" style={{ animationDelay: '0.2s' }} />
          <div className="w-3 h-3 rounded-full bg-green-500/80 animate-pulse" style={{ animationDelay: '0.4s' }} />
        </div>
        <div className="text-gray-400 text-sm">bash ~ /kgs-automation</div>
      </div>

      {/* Terminal Content */}
      <div className="p-4 font-mono text-sm">
        <div className="flex items-center text-gray-400 mb-2">
          <span className="text-green-400">user@kgs</span>
          <span className="text-white mx-1">:</span>
          <span className="text-blue-400">~/automation</span>
          <span className="text-white mx-1">$</span>
        </div>
        <div className="text-blue-400/90">
          <pre className="whitespace-pre-wrap break-words">
            <code>
              {displayedCode}
              {cursorVisible && <span className="inline-block w-2 h-4 bg-blue-400/70 ml-1 animate-blink">|</span>}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
}