import React from 'react';
import { Header } from '../components/Header';
import { Hero } from '../components/Hero';
import { ServicesSection } from '../components/ServicesSection';
import { PartnersSection } from '../components/PartnersSection';
import { BlogSection } from '../components/BlogSection';
import { ContactSection } from '../components/ContactSection';
import { Footer } from '../components/Footer';

export function HomePage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <Hero />
      <ServicesSection />
      <PartnersSection />
      <BlogSection />
      <ContactSection />
      <Footer />
    </div>
  );
}