import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Factory, Award, MapPin, Users } from 'lucide-react';

export function AboutPage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Hakkımızda | KGS Otomasyon</title>
        <meta name="description" content="KGS Otomasyon Medikal San. Tic. Ltd. Şti. 2013 yılından bu yana Kocaeli'de endüstriyel otomasyon ve medikal çözümler sunmaktadır." />
        <meta name="keywords" content="KGS Otomasyon, Kocaeli otomasyon, endüstriyel otomasyon, medikal sistemler, PLC programlama" />
        <link rel="canonical" href="https://kgsotomasyon.com/hakkimizda" />
      </Helmet>
      
      <Header />
      
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-white mb-8">Hakkımızda</h1>
          
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
              <h2 className="text-2xl font-semibold text-white mb-4 flex items-center">
                <Factory className="w-6 h-6 mr-2 text-blue-400" />
                Şirket Profili
              </h2>
              <div className="text-gray-300 leading-relaxed space-y-4">
                <p>
                  KGS Otomasyon Medikal San. Tic. Ltd. Şti. piyasanın ihtiyaç duyduğu kalite ve beceri boşluğunun fark edilmesiyle konularında uzman bir ekip tarafından 2013 yılında Kocaeli ilinde kurulmuştur.
                </p>
                <p>
                  KGS Otomasyon ve KGS Medikal olarak kendi içinde iki bölüme ayrılan şirketimiz, otomasyon alanı ile medikal sağlık alanın birleşimi ve güçlendirilip geliştirilmesini hedeflemiştir.
                </p>
              </div>
            </div>
            
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
              <h2 className="text-2xl font-semibold text-white mb-4 flex items-center">
                <Award className="w-6 h-6 mr-2 text-blue-400" />
                Değerlerimiz
              </h2>
              <ul className="space-y-4 text-gray-300">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-400 rounded-full mr-3"></span>
                  Kalite
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-400 rounded-full mr-3"></span>
                  Hızlı çözümler
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-400 rounded-full mr-3"></span>
                  Dürüstlük ve sözünü tutma
                </li>
              </ul>
            </div>
          </div>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
              <h2 className="text-2xl font-semibold text-white mb-4 flex items-center">
                <MapPin className="w-6 h-6 mr-2 text-blue-400" />
                Lokasyon
              </h2>
              <p className="text-gray-300 leading-relaxed">
                Kocaeli ilinde 150 M² kendi öz malında hizmet veren firmamız otomasyon ve medikal alanda projelerini gerçekleştirmektedir.
              </p>
            </div>
            
            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
              <h2 className="text-2xl font-semibold text-white mb-4 flex items-center">
                <Users className="w-6 h-6 mr-2 text-blue-400" />
                Ekibimiz
              </h2>
              <p className="text-gray-300 leading-relaxed">
                Konularında uzman ve deneyimli ekibimizle müşterilerimize en kaliteli hizmeti sunmayı hedefliyoruz.
              </p>
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
}