import React from 'react';
import { LucideIcon } from 'lucide-react';
import { CodeWindow } from './CodeWindow';

interface ServiceCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  codeSnippet: string;
}

export function ServiceCard({ title, description, icon: Icon, codeSnippet }: ServiceCardProps) {
  return (
    <div className="group h-[350px] relative bg-white/5 backdrop-blur-sm p-4 rounded-xl hover:bg-white/10 transition-all transform hover:-translate-y-2 duration-300">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-600/10 to-purple-600/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      
      <div className="relative z-10 h-full flex flex-col">
        <div className="flex items-center mb-3">
          <div className="p-2 bg-blue-500/10 rounded-lg mr-3">
            <Icon className="w-6 h-6 text-blue-400" />
          </div>
          <h3 className="text-lg font-semibold text-white">{title}</h3>
        </div>
        
        <p className="text-gray-300 text-sm mb-4">{description}</p>
        
        <div className="flex-grow overflow-hidden rounded-lg">
          <CodeWindow code={codeSnippet} />
        </div>
      </div>
    </div>
  );
}