import React from 'react';

const references = [
  {
    name: "BASF",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Basf.jpg",
    description: "Endüstriyel otomasyon çözümleri"
  },
  {
    name: "Betek",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Betek.jpg",
    description: "PLC programlama ve sistem entegrasyonu"
  },
  {
    name: "Diversey",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Diversey.jpg",
    description: "Otomasyon sistemleri"
  },
  {
    name: "Petrol Ofisi",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Petrol.jpg",
    description: "Endüstriyel kontrol sistemleri"
  },
  {
    name: "Corning",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Corning.jpg",
    description: "SCADA sistemleri"
  },
  {
    name: "Cenesuyu",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Cenesuyu.jpg",
    description: "Üretim hattı otomasyonu"
  },
  {
    name: "Bosal",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/Bosal.jpg",
    description: "PLC ve HMI uygulamaları"
  },
  {
    name: "Sandoz",
    logo: "http://kgsotomasyon.com/wp-content/uploads/2014/05/sandoz.jpg",
    description: "Endüstriyel otomasyon projeleri"
  }
];

export function ReferencesGrid() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {references.map((reference, index) => (
        <div 
          key={index}
          className="bg-white/5 backdrop-blur-sm rounded-lg p-6 hover:bg-white/10 transition-all duration-300 group"
        >
          <div className="aspect-[3/2] mb-4 bg-white rounded-lg p-4 flex items-center justify-center">
            <img
              src={reference.logo}
              alt={`${reference.name} logo`}
              className="max-h-full max-w-full object-contain transform group-hover:scale-105 transition-transform duration-300"
              onError={(e) => {
                e.currentTarget.src = `https://via.placeholder.com/300x200?text=${reference.name}`;
              }}
            />
          </div>
          <h3 className="text-lg font-semibold text-white mb-2">{reference.name}</h3>
          <p className="text-gray-400 text-sm">{reference.description}</p>
        </div>
      ))}
    </div>
  );
}