import React from 'react';

export function RobotArm() {
  return (
    <div className="absolute right-0 bottom-0 w-64 h-64 opacity-20 animate-robot">
      <svg viewBox="0 0 100 100" className="w-full h-full">
        <g className="robot-arm">
          <path 
            d="M50,90 L50,70 L70,50 L90,50" 
            className="stroke-blue-400"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <circle 
            cx="90" 
            cy="50" 
            r="5" 
            className="fill-blue-400 animate-pulse"
          />
          <path 
            d="M50,70 L30,50" 
            className="stroke-blue-400"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
}