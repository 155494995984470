import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { postsApi } from '../lib/api/posts';
import { Calendar, Tag } from 'lucide-react';

export function BlogPostPage() {
  const { id } = useParams<{ id: string }>();
  const { data: post, isLoading } = useQuery({
    queryKey: ['post', id],
    queryFn: () => postsApi.getById(Number(id))
  });

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Header />
        <div className="container mx-auto px-4 py-20">
          <div className="animate-pulse space-y-4">
            <div className="bg-white/5 h-8 w-2/3 rounded"></div>
            <div className="bg-white/5 h-96 rounded"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Header />
        <div className="container mx-auto px-4 py-20">
          <div className="text-center text-white">
            <h1 className="text-4xl font-bold mb-4">Yazı Bulunamadı</h1>
            <p className="text-gray-400">Aradığınız blog yazısı bulunamadı.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <article className="container mx-auto px-4 py-20">
        {post.featuredImage && (
          <div className="relative h-96 mb-8 rounded-xl overflow-hidden">
            <img
              src={post.featuredImage}
              alt={post.title}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-4">{post.title}</h1>
          
          <div className="flex items-center text-sm text-gray-400 mb-8 space-x-4">
            <span className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              {new Date(post.date).toLocaleDateString('tr-TR')}
            </span>
            {post.categories.length > 0 && (
              <span className="flex items-center">
                <Tag className="w-4 h-4 mr-1" />
                {post.categories.join(', ')}
              </span>
            )}
          </div>
          
          <div 
            className="prose prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </article>
      <Footer />
    </div>
  );
}