import React from 'react';
import { ElectricEffect } from './animations/ElectricEffect';

const partners = [
  {
    name: "Weidmuller",
    logo: "https://kgsotomasyon.com/weidmuller.png",
  },
  {
    name: "SICK",
    logo: "https://kgsotomasyon.com/sick.png",
  },
  {
    name: "MURR",
    logo: "https://kgsotomasyon.com/murr.png",
  },
  {
    name: "Siemens",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Siemens-logo.svg/2560px-Siemens-logo.svg.png",
  },
  {
    name: "Phoenix Contact",
    logo: "https://kgsotomasyon.com/Phoenix_Contact.png",
  }
];

export function PartnersSection() {
  return (
    <div className="py-12 bg-gradient-to-b from-blue-900 to-gray-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:32px_32px]" />
      
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-white text-center mb-12">Partnerlerimiz</h2>
        
        <div className="flex flex-wrap justify-center gap-8 relative z-10">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="group relative w-40 h-40 flex items-center justify-center"
            >
              {/* Background Glow */}
              <div className="absolute inset-0 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-all duration-300" />
              
              {/* Electric Effect */}
              <ElectricEffect />
              
              {/* Logo Container */}
              <div className="relative w-full h-full bg-white rounded-lg backdrop-blur-sm p-4 transform hover:scale-105 transition-all duration-300 flex items-center justify-center group-hover:shadow-[0_0_15px_rgba(59,130,246,0.5)]">
                {/* Animated Border */}
                <div className="absolute inset-0 rounded-lg overflow-hidden">
                  <div className="absolute top-0 left-0 w-2 h-2 bg-blue-400 rounded-full animate-ping" />
                  <div className="absolute top-0 right-0 w-2 h-2 bg-blue-400 rounded-full animate-ping delay-100" />
                  <div className="absolute bottom-0 left-0 w-2 h-2 bg-blue-400 rounded-full animate-ping delay-200" />
                  <div className="absolute bottom-0 right-0 w-2 h-2 bg-blue-400 rounded-full animate-ping delay-300" />
                </div>
                
                {/* Logo */}
                <img
                  src={partner.logo}
                  alt={partner.name}
                  className="w-28 h-28 object-contain group-hover:opacity-100 transition-opacity duration-300"
                  onError={(e) => {
                    e.currentTarget.src = 'https://via.placeholder.com/112x112?text=' + partner.name;
                  }}
                />
                
                {/* Hover Effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-blue-500/10 to-blue-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
              
              {/* Partner Name */}
              <div className="absolute -bottom-6 left-0 right-0 text-center">
                <span className="text-sm text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {partner.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Background Effects */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-900/20 to-transparent" />
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px bg-gradient-to-r from-transparent via-blue-400/30 to-transparent animate-pulse"
            style={{
              top: `${(i + 1) * 25}%`,
              animationDelay: `${i * 0.5}s`,
            }}
          />
        ))}
      </div>
    </div>
  );
}