import React from 'react';

export function CodeBlock() {
  return (
    <div className="absolute left-0 top-1/4 w-64 opacity-20">
      <div className="space-y-2">
        {[...Array(5)].map((_, i) => (
          <div 
            key={i}
            className="h-3 bg-blue-400/30 rounded animate-typing"
            style={{
              width: `${Math.random() * 50 + 50}%`,
              animationDelay: `${i * 0.2}s`
            }}
          />
        ))}
      </div>
    </div>
  );
}