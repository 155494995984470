import { Routes, Route, Navigate } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { ActivitiesPage } from './pages/ActivitiesPage';
import { ApplicationsPage } from './pages/ApplicationsPage';
import { MaintenancePage } from './pages/MaintenancePage';
import { ReferencesPage } from './pages/ReferencesPage';
import { ContactPage } from './pages/ContactPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
import { BlogPage } from './pages/BlogPage';
import { BlogPostPage } from './pages/BlogPostPage';

export function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/hakkimizda" element={<AboutPage />} />
      <Route path="/faliyetlerimiz" element={<ActivitiesPage />} />
      <Route path="/uygulamalar" element={<ApplicationsPage />} />
      <Route path="/tamir-bakim" element={<MaintenancePage />} />
      <Route path="/referanslar" element={<ReferencesPage />} />
      <Route path="/iletisim" element={<ContactPage />} />
      <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
      <Route path="/kullanim-kosullari" element={<TermsOfService />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/:id" element={<BlogPostPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}