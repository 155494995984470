import React from 'react';
import { 
  Monitor, 
  Cpu, 
  RotateCw, 
  Box, 
  Droplet, 
  Database,
  Gauge,
  Cable,
  Factory,
  Package,
  Thermometer,
  Forklift
} from 'lucide-react';
import { QuoteRequestForm } from '../forms/QuoteRequestForm';

const applications = [
  {
    title: "LED Panel",
    category: "Görüntüleme Sistemleri",
    description: "Profinet ile haberleşen LED panellerimiz ile metre ve alarm bilgilerini ekranınızda gösterebilirsiniz.",
    icon: Monitor
  },
  {
    title: "Çözücü Otomasyonu",
    category: "Endüstriyel Kontrol",
    description: "Endüstriyel çözücü sistemleri için özel otomasyon çözümleri ve kontrol sistemleri.",
    icon: RotateCw
  },
  {
    title: "Sarıcı Otomasyonu",
    category: "Üretim Sistemleri",
    description: "Kablo ve tel üretimi için özelleştirilmiş sarıcı otomasyon sistemleri.",
    icon: Box
  },
  {
    title: "Tank SCADA Sistemleri",
    category: "Proses Kontrolü",
    description: "Tank seviye kontrolü, reçete yönetimi ve gerçek zamanlı izleme sistemleri.",
    icon: Database
  },
  {
    title: "Enerji İzleme",
    category: "Verimlilik Sistemleri",
    description: "Endüstriyel tesisler için enerji tüketimi izleme ve optimizasyon sistemleri.",
    icon: Gauge
  },
  {
    title: "Kablo Makinaları",
    category: "Üretim Otomasyonu",
    description: "Kablo üretim hatları için özel otomasyon ve kontrol sistemleri.",
    icon: Cable
  },
  {
    title: "Döküm Tesisi",
    category: "Endüstriyel Otomasyon",
    description: "Döküm tesisleri için tam entegre otomasyon ve kontrol çözümleri.",
    icon: Factory
  },
  {
    title: "Paketleme Sistemleri",
    category: "Üretim Otomasyonu",
    description: "Otomatik paketleme ve etiketleme sistemleri için otomasyon çözümleri.",
    icon: Package
  }
];

const quoteServices = [
  { value: 'led-panel', label: 'LED Panel' },
  { value: 'cozucu-otomasyonu', label: 'Çözücü Otomasyonu' },
  { value: 'sarici-otomasyonu', label: 'Sarıcı Otomasyonu' },
  { value: 'tank-scada', label: 'Tank Scada Otomasyonu' },
  { value: 'kablo-makinalari', label: 'Kablo Makinaları' },
  { value: 'enerji-izleme', label: 'Enerji İzleme' },
  { value: 'dokum-tesisi', label: 'Döküm Tesisi' },
  { value: 'paketleme-sistemleri', label: 'Paketleme Sistemleri' },
  { value: 'sogutma-sistemleri', label: 'Soğutma Sistemleri' },
  { value: 'tasima-depolama', label: 'Taşıma ve Depolama' },
  { value: 'plc-sistemleri', label: 'PLC Sistemleri' },
  { value: 'sivi-dolum', label: 'Sıvı Dolum Sistemleri' }
];

export function ApplicationsGrid() {
  return (
    <div className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {applications.map((app, index) => {
                const Icon = app.icon;
                return (
                  <div
                    key={index}
                    className="bg-white/5 backdrop-blur-sm rounded-lg p-6 hover:bg-white/10 transition-all duration-300 group"
                  >
                    <div className="flex items-center mb-4">
                      <div className="p-3 bg-blue-500/10 rounded-lg mr-4">
                        <Icon className="w-6 h-6 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white group-hover:text-blue-400 transition-colors">
                          {app.title}
                        </h3>
                        <span className="text-sm text-gray-400">{app.category}</span>
                      </div>
                    </div>
                    <p className="text-gray-300 text-sm leading-relaxed">
                      {app.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          
          <div className="lg:col-span-1">
            <QuoteRequestForm 
              services={quoteServices}
              title="Teklif İste"
              description="Otomasyon projeleriniz için detaylı bilgi ve fiyat teklifi almak için formu doldurun."
            />
          </div>
        </div>
      </div>
    </div>
  );
}