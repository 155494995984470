import React from 'react';
import { Header } from '../components/Header';
import { ContactSection } from '../components/ContactSection';

export function ContactPage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="pt-20">
        <ContactSection />
      </div>
    </div>
  );
}