import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { postsApi } from '../lib/api/posts';
import { Calendar, Tag } from 'lucide-react';
import { EmptyState } from '../components/ui/EmptyState';

export function BlogPage() {
  const { data: posts, isLoading } = useQuery({
    queryKey: ['posts'],
    queryFn: postsApi.getAll
  });

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="container mx-auto px-4 py-20">
        <h1 className="text-4xl font-bold text-white mb-12">Blog</h1>
        
        {isLoading ? (
          <div className="animate-pulse space-y-8">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white/5 h-64 rounded-lg"></div>
            ))}
          </div>
        ) : !posts || posts.length === 0 ? (
          <EmptyState
            title="Henüz Blog Yazısı Yok"
            description="Çok yakında burada endüstriyel otomasyon, PLC programlama ve teknoloji üzerine yazılarımızı bulabilirsiniz."
            action={{
              label: "Ana Sayfaya Dön",
              href: "/"
            }}
          />
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map((post) => (
              <article 
                key={post.id}
                className="bg-white/5 backdrop-blur-sm rounded-lg overflow-hidden hover:bg-white/10 transition-all duration-300 group"
              >
                {post.featuredImage && (
                  <div className="relative h-48 overflow-hidden">
                    <img
                      src={post.featuredImage}
                      alt={post.title}
                      className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                )}
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-400 mb-3 space-x-4">
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {new Date(post.date).toLocaleDateString('tr-TR')}
                    </span>
                    {post.categories.length > 0 && (
                      <span className="flex items-center">
                        <Tag className="w-4 h-4 mr-1" />
                        {post.categories[0]}
                      </span>
                    )}
                  </div>
                  <h2 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                    {post.title}
                  </h2>
                  <div 
                    className="text-gray-300 text-sm mb-4 line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: post.excerpt || post.content.slice(0, 150) + '...' }}
                  />
                  <a 
                    href={`/blog/${post.id}`}
                    className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                  >
                    Devamını Oku
                    <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M5 12h14M12 5l7 7-7 7"/>
                    </svg>
                  </a>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}