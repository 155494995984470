import React from 'react';

export function Circuit() {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="absolute w-full h-full">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute bg-gradient-to-r from-transparent via-blue-400/20 to-transparent h-px animate-circuit"
            style={{
              top: `${Math.random() * 100}%`,
              left: '-100%',
              width: '100%',
              animationDelay: `${i * 2}s`
            }}
          />
        ))}
      </div>
    </div>
  );
}