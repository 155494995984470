import React from 'react';

export function BinaryCode() {
  const binaryChars = ['0', '1'];
  
  return (
    <div className="absolute right-10 top-20 opacity-20 text-xs font-mono">
      {[...Array(10)].map((_, row) => (
        <div key={row} className="flex">
          {[...Array(15)].map((_, col) => (
            <span
              key={col}
              className="animate-binary"
              style={{
                animationDelay: `${(row + col) * 0.1}s`
              }}
            >
              {binaryChars[Math.floor(Math.random() * 2)]}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}