import React from 'react';
import { Mail } from 'lucide-react';

export function ApplicationsHero() {
  return (
    <div className="relative pt-32 pb-20 bg-gradient-to-b from-gray-900 via-blue-900 to-gray-900">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px]" />
      </div>
      
      <div className="relative container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Endüstriyel Otomasyon Uygulamaları
          </h1>
          <p className="text-lg text-gray-300 mb-8">
            KGS Otomasyon olarak, endüstriyel tesisler için özel çözümler ve otomasyon sistemleri geliştiriyoruz. 
            LED panellerden SCADA sistemlerine, PLC programlamadan endüstriyel PC çözümlerine kadar geniş bir yelpazede hizmet sunuyoruz.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <a
              href="mailto:otomasyon@kgs.com.tr"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
            >
              <Mail className="w-5 h-5 mr-2" />
              Detaylı Bilgi Al
            </a>
            <a
              href="tel:+908503029757"
              className="inline-flex items-center px-6 py-3 bg-white/10 text-white rounded-full hover:bg-white/20 transition-colors"
            >
              0850 302 97 57
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}