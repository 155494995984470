import React from 'react';
import { Link } from 'react-router-dom';

export function FooterLinks() {
  return (
    <div className="flex flex-col items-center space-y-4 border-t border-gray-800 pt-8 mt-12">
      <p className="text-gray-400 text-center">
        © {new Date().getFullYear()} KGS Otomasyon. Tüm hakları saklıdır.
      </p>
      <nav className="flex flex-wrap justify-center gap-6">
        <Link 
          to="/blog"
          className="text-gray-400 hover:text-blue-500 transition-colors"
        >
          Blog
        </Link>
        <Link 
          to="/gizlilik-politikasi" 
          className="text-gray-400 hover:text-blue-500 transition-colors"
        >
          Gizlilik Politikası
        </Link>
        <Link 
          to="/kullanim-kosullari" 
          className="text-gray-400 hover:text-blue-500 transition-colors"
        >
          Kullanım Koşulları
        </Link>
      </nav>
    </div>
  );
}