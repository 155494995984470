import React from 'react';
import { Calendar, Tag } from 'lucide-react';
import { Post } from '../../lib/api/posts';
import { formatDate } from '../../lib/utils/date';
import { truncateHTML } from '../../lib/utils/string';

interface PostCardProps {
  post: Post;
}

export function PostCard({ post }: PostCardProps) {
  return (
    <article className="bg-white/5 backdrop-blur-sm rounded-lg overflow-hidden hover:bg-white/10 transition-all duration-300 group">
      {post.featuredImage && (
        <div className="relative h-48 overflow-hidden">
          <img
            src={post.featuredImage}
            alt={post.title}
            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
        </div>
      )}
      <div className="p-6">
        <div className="flex items-center text-sm text-gray-400 mb-3 space-x-4">
          <span className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {formatDate(post.date)}
          </span>
          {post.categories.length > 0 && (
            <span className="flex items-center">
              <Tag className="w-4 h-4 mr-1" />
              {post.categories[0]}
            </span>
          )}
        </div>
        <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
          {post.title}
        </h3>
        <div 
          className="text-gray-300 text-sm mb-4 line-clamp-3"
          dangerouslySetInnerHTML={{ 
            __html: post.excerpt || truncateHTML(post.content, 150)
          }}
        />
        <a 
          href={`/blog/${post.id}`}
          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
        >
          Devamını Oku
          <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M5 12h14M12 5l7 7-7 7"/>
          </svg>
        </a>
      </div>
    </article>
  );
}