import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Activity, CircuitBoard, Cpu, Database } from 'lucide-react';

export function ActivitiesPage() {
  const activities = [
    {
      title: "PLC Programlama",
      description: "Endüstriyel otomasyon sistemleri için özel PLC programlama çözümleri sunuyoruz. Siemens, Allen Bradley ve diğer önde gelen markaların PLC'leri için programlama hizmetleri.",
      icon: Cpu,
      features: [
        "SIMATIC STEP 7 ve TIA Portal programlama",
        "Allen Bradley RSLogix ve Studio 5000",
        "HMI arayüz tasarımı",
        "PLC program optimizasyonu"
      ]
    },
    {
      title: "SCADA Sistemleri",
      description: "Modern endüstriyel tesisler için kapsamlı SCADA çözümleri geliştiriyoruz. Üretim süreçlerinizi gerçek zamanlı izleme ve kontrol imkanı.",
      icon: Database,
      features: [
        "Gerçek zamanlı veri toplama",
        "Üretim raporlama",
        "Alarm yönetimi",
        "Trend analizi"
      ]
    },
    {
      title: "Endüstriyel Otomasyon",
      description: "Fabrika otomasyonu ve üretim hattı modernizasyonu için uçtan uca çözümler. Verimlilik ve kalite artışı için özelleştirilmiş sistemler.",
      icon: CircuitBoard,
      features: [
        "Üretim hattı otomasyonu",
        "Robot entegrasyonu",
        "Kalite kontrol sistemleri",
        "Endüstri 4.0 uygulamaları"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      
      {/* Hero Section */}
      <div className="relative pt-32 pb-20 bg-gradient-to-b from-gray-900 via-blue-900 to-gray-900">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px]" />
        </div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Faliyetlerimiz
            </h1>
            <p className="text-lg text-gray-300">
              Endüstriyel otomasyon çözümleri ve PLC programlama konusunda uzman ekibimizle
              işletmenizin verimliliğini artırıyoruz.
            </p>
          </div>
        </div>
      </div>

      {/* Activities Grid */}
      <div className="py-20 bg-gray-900">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {activities.map((activity, index) => {
              const Icon = activity.icon;
              return (
                <div 
                  key={index}
                  className="bg-white/5 backdrop-blur-sm rounded-lg p-6 hover:bg-white/10 transition-all duration-300"
                >
                  <div className="flex items-center mb-4">
                    <div className="p-2 bg-blue-500/10 rounded-lg mr-3">
                      <Icon className="w-6 h-6 text-blue-400" />
                    </div>
                    <h3 className="text-xl font-semibold text-white">
                      {activity.title}
                    </h3>
                  </div>
                  <p className="text-gray-300 mb-6">
                    {activity.description}
                  </p>
                  <ul className="space-y-2">
                    {activity.features.map((feature, i) => (
                      <li key={i} className="flex items-center text-gray-400">
                        <Activity className="w-4 h-4 mr-2 text-blue-400" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-20 bg-gradient-to-t from-gray-900 via-blue-900 to-gray-900">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Projeniz için Bizimle İletişime Geçin
          </h2>
          <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
            Endüstriyel otomasyon ihtiyaçlarınız için uzman ekibimiz yanınızda.
            Size özel çözümler için hemen iletişime geçin.
          </p>
          <a
            href="/iletisim"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
          >
            İletişime Geç
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}