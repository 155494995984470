import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import axios from 'axios';
import { Mail } from 'lucide-react';

const quoteSchema = z.object({
  name: z.string().min(2, 'İsim en az 2 karakter olmalıdır'),
  company: z.string().min(2, 'Firma adı en az 2 karakter olmalıdır'),
  email: z.string().email('Geçerli bir email adresi giriniz'),
  phone: z.string().min(10, 'Geçerli bir telefon numarası giriniz'),
  service: z.string().min(1, 'Lütfen bir hizmet seçiniz'),
  details: z.string().min(10, 'Detaylar en az 10 karakter olmalıdır')
});

type QuoteForm = z.infer<typeof quoteSchema>;

interface QuoteRequestFormProps {
  services: { value: string; label: string }[];
  title?: string;
  description?: string;
}

export function QuoteRequestForm({ services, title = "Teklif İste", description }: QuoteRequestFormProps) {
  const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<QuoteForm>({
    resolver: zodResolver(quoteSchema)
  });

  const onSubmit = async (data: QuoteForm) => {
    try {
      const response = await axios.post('/api/quote.php', data);
      if (response.data.success) {
        alert('Teklif talebiniz başarıyla gönderildi. En kısa sürede size dönüş yapacağız.');
        reset();
      }
    } catch (error) {
      alert('Teklif talebi gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
    }
  };

  return (
    <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
      <div className="flex items-center mb-6">
        <Mail className="w-6 h-6 text-blue-400 mr-3" />
        <h3 className="text-xl font-semibold text-white">{title}</h3>
      </div>
      
      {description && (
        <p className="text-gray-300 mb-6">{description}</p>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-300 mb-2">Ad Soyad</label>
            <input 
              {...register('name')}
              className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
            />
            {errors.name && (
              <p className="text-red-400 text-sm mt-1">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Firma Adı</label>
            <input 
              {...register('company')}
              className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
            />
            {errors.company && (
              <p className="text-red-400 text-sm mt-1">{errors.company.message}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-300 mb-2">E-posta</label>
            <input 
              {...register('email')}
              type="email"
              className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
            />
            {errors.email && (
              <p className="text-red-400 text-sm mt-1">{errors.email.message}</p>
            )}
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Telefon</label>
            <input 
              {...register('phone')}
              type="tel"
              className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
            />
            {errors.phone && (
              <p className="text-red-400 text-sm mt-1">{errors.phone.message}</p>
            )}
          </div>
        </div>

        <div>
          <label className="block text-gray-300 mb-2">Hizmet</label>
          <select 
            {...register('service')}
            className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
          >
            <option value="">Hizmet Seçiniz</option>
            {services.map((service) => (
              <option key={service.value} value={service.value}>
                {service.label}
              </option>
            ))}
          </select>
          {errors.service && (
            <p className="text-red-400 text-sm mt-1">{errors.service.message}</p>
          )}
        </div>

        <div>
          <label className="block text-gray-300 mb-2">Detaylar</label>
          <textarea 
            {...register('details')}
            rows={4}
            className="w-full bg-white/10 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
            placeholder="Projeniz hakkında detaylı bilgi veriniz..."
          ></textarea>
          {errors.details && (
            <p className="text-red-400 text-sm mt-1">{errors.details.message}</p>
          )}
        </div>

        <button 
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          {isSubmitting ? 'Gönderiliyor...' : 'Teklif İste'}
        </button>
      </form>
    </div>
  );
}