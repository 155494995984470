import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { ReferencesGrid } from '../components/ReferencesGrid';
import { Mail } from 'lucide-react';

export function ReferencesPage() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Referanslarımız | KGS Otomasyon</title>
        <meta name="description" content="KGS Otomasyon'un güvenilir iş ortakları ve başarılı projeleri. BASF, Betek, Diversey, Petrol Ofisi ve daha fazlası." />
        <meta name="keywords" content="KGS Otomasyon referanslar, endüstriyel otomasyon projeleri, Kocaeli otomasyon referansları" />
        <link rel="canonical" href="https://kgsotomasyon.com/referanslar" />
      </Helmet>
      
      <Header />
      
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h1 className="text-4xl font-bold text-white mb-4">Referanslarımız</h1>
            <p className="text-gray-300">
              Yılların deneyimi ve uzmanlığıyla birçok saygın firmaya hizmet verdik. 
              İşte bizimle çalışmayı tercih eden firmalardan bazıları.
            </p>
          </div>

          <ReferencesGrid />

          <div className="mt-12 text-center">
            <p className="text-gray-300 mb-6">
              Ayrıntılı referans listesi için bizimle iletişime geçebilirsiniz.
            </p>
            <a
              href="mailto:otomasyon@kgs.com.tr"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
            >
              <Mail className="w-5 h-5 mr-2" />
              E-posta Gönder
            </a>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}