import React from 'react';
import { Monitor, AlertTriangle, Thermometer, Zap, RefreshCw, ArrowUpCircle } from 'lucide-react';
import { QuoteRequestForm } from '../forms/QuoteRequestForm';

const commonIssues = [
  {
    icon: Monitor,
    title: "Ekran Arızaları",
    description: "Dokunmatik ekran ve LCD ekran kırılmaları, kalibrasyon sorunları"
  },
  {
    icon: AlertTriangle,
    title: "Elektriksel Sorunlar",
    description: "Açılmama, kapanma ve güç sorunları"
  },
  {
    icon: Thermometer,
    title: "Isınma Sorunları",
    description: "Aşırı ısınma kaynaklı kapanma sorunları"
  },
  {
    icon: Zap,
    title: "Güç Sorunları",
    description: "Güç kaynağı ve elektrik beslemesi arızaları"
  },
  {
    icon: RefreshCw,
    title: "Sistem Yenileme",
    description: "Program yedekleme ve sistem güncelleme"
  },
  {
    icon: ArrowUpCircle,
    title: "Versiyon Güncelleme",
    description: "Firmware ve yazılım güncellemeleri"
  }
];

const quoteServices = [
  { value: 'dokunmatik-ekran', label: 'Dokunmatik Ekran Tamiri' },
  { value: 'lcd-ekran', label: 'LCD Ekran Tamiri' },
  { value: 'isinma-sorunu', label: 'Isınma Sorunu Çözümü' },
  { value: 'elektriksel-ariza', label: 'Elektriksel Arıza Tamiri' },
  { value: 'sistem-yenileme', label: 'Sistem Yenileme' },
  { value: 'yazilim-guncelleme', label: 'Yazılım Güncelleme' }
];

export function OperatorPanelRepair() {
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 bg-white/5 backdrop-blur-sm rounded-lg p-8">
          <h2 className="text-2xl font-bold text-white mb-4">Operatör Panel Tamiri</h2>
          <p className="text-gray-300 mb-6">
            Operatör panellerde meydana gelen arızaların giderilmesinde siz değerli müşterilerimize 
            destek vermekteyiz. Kocaeli, İstanbul, Bursa, Düzce, Sakarya, Bolu illerinden operatör 
            panellerinin tamiri için ücretsiz kargo ile gönderebilirsiniz.
          </p>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {commonIssues.map((issue, index) => {
              const Icon = issue.icon;
              return (
                <div 
                  key={index}
                  className="bg-white/5 rounded-lg p-6 hover:bg-white/10 transition-all duration-300"
                >
                  <Icon className="w-8 h-8 text-blue-400 mb-4" />
                  <h3 className="text-lg font-semibold text-white mb-2">{issue.title}</h3>
                  <p className="text-gray-400 text-sm">{issue.description}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="lg:col-span-1">
          <QuoteRequestForm 
            services={quoteServices}
            title="Servis Talebi"
            description="Operatör panel tamiri için servis talebi oluşturun. En kısa sürede size dönüş yapacağız."
          />
        </div>
      </div>
    </div>
  );
}